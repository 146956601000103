import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const AboutUsPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">About Us</h1>

        {/* Company Story & Mission */}
        <div className="mb-12">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">Our Story</h2>
          <p className="text-gray-600 leading-relaxed">
            Our platform was born out of a need to solve the challenges faced by
            shift-based teams. From healthcare to hospitality, we aim to
            simplify scheduling, enhance workforce engagement, and optimize
            operations for organizations of all sizes. We are committed to
            helping teams succeed by reducing scheduling headaches and improving
            work-life balance.
          </p>
        </div>

        {/* Team / Leadership */}
        <div className="mb-12">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">
            Meet Our Team
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md text-center">
              <img
                src="/assets/founder.jpg"
                alt="Founder"
                className="w-24 h-24 rounded-full mx-auto mb-4"
              />
              <h3 className="text-xl font-semibold text-gray-800">
                Joel Newman
              </h3>
              <p className="text-gray-600">Founder & CEO</p>
              <p className="text-gray-500 mt-2">
                Passionate about innovative solutions that improve team
                efficiency and engagement.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md text-center">
              <img
                src="/assets/coo.jpg"
                alt="COO"
                className="w-24 h-24 rounded-full mx-auto mb-4"
              />
              <h3 className="text-xl font-semibold text-gray-800">
                Muhammad Nauman
              </h3>
              <p className="text-gray-600">Founder & CTO</p>
              <p className="text-gray-500 mt-2">
                Dedicated to streamlining operations and fostering growth across
                industries.
              </p>
            </div>
          </div>
        </div>

        {/* Culture & Values */}
        <div className="mb-12">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">
            Our Culture & Values
          </h2>
          <p className="text-gray-600 leading-relaxed">
            At the heart of our organization is a commitment to professionalism,
            inclusivity, and innovation. We believe in creating solutions that
            not only improve operations but also enhance the lives of our users.
            From reducing stress to fostering better communication, our goal is
            to empower teams and promote a positive work culture.
          </p>
        </div>

        {/* Press & Partnerships */}
        <div className="mb-12">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">
            Press & Partnerships
          </h2>
          <p className="text-gray-600 leading-relaxed">
            We are proud to have been featured in leading industry publications
            and to collaborate with respected partners across various sectors.
            These relationships reinforce our commitment to delivering the best
            solutions for our users.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUsPage;
