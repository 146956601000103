import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const SolutionsPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">Solutions</h1>

        <div className="mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            How It Works
          </h2>
          <div className="space-y-6">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                Step 1: Set up your organization
              </h3>
              <p className="text-gray-600">
                Configure your organization with locations, roles, and key
                details to prepare for efficient scheduling.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                Step 2: Create shifts & publish
              </h3>
              <p className="text-gray-600">
                Build and publish shifts with ease, ensuring that all roles and
                requirements are met.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                Step 3: Employees get notified & confirm
              </h3>
              <p className="text-gray-600">
                Employees receive notifications for their assigned shifts and
                can confirm availability in real-time.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                Step 4: Managers track attendance & fill gaps
              </h3>
              <p className="text-gray-600">
                Monitor attendance and address last-minute gaps efficiently to
                maintain smooth operations.
              </p>
            </div>
          </div>
        </div>

        <div className="mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Use Cases by Industry
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                Healthcare
              </h3>
              <p className="text-gray-600">
                Minimize nurse overtime, rotate shifts, and ensure compliance
                with rest requirements.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                Hospitality
              </h3>
              <p className="text-gray-600">
                Manage multiple departments like kitchen, front-of-house, and
                housekeeping seamlessly.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                Retail
              </h3>
              <p className="text-gray-600">
                Handle peak periods, sudden staff shortages, and holiday rush
                with ease.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                Logistics/Warehousing
              </h3>
              <p className="text-gray-600">
                Cover shifts across different job functions and ensure 24/7
                coverage efficiently.
              </p>
            </div>
          </div>
        </div>

        <div className="mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Visual Workflow
          </h2>
          <p className="text-gray-600 mb-4">
            A simplified infographic or flowchart showcasing how our platform
            streamlines scheduling from start to finish.
          </p>
          <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            {/* Placeholder for flowchart or infographic */}
            <p className="text-gray-500 italic text-center">
              [Flowchart/Infographic will be here]
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SolutionsPage;
