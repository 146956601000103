import React, { useState, lazy, Suspense } from "react";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import Footer from "../components/Footer";
import SchedulingSection from "../components/SchedulingFeature";

// Lazy load components for better performance
const FeaturesSection = lazy(() => import("../components/FeaturesSection"));
const TestimonialsSection = lazy(() =>
  import("../components/TestimonialsSection")
);
const FAQSection = lazy(() => import("../components/FAQSection"));
const VideoSection = lazy(() => import("../components/VideoSection"));
const UpcomingSection = lazy(() => import("../components/UpcomingSection"));
const ShiftManagementSection = lazy(() =>
  import("../components/ShiftManagementSection")
);
const GetSection = lazy(() => import("../components/GetSection"));
const StatisticsSection = lazy(() => import("../components/StatisticsSection"));
const IntegrationSection = lazy(() =>
  import("../components/IntegrationSection")
);
const ComparisonSection = lazy(() => import("../components/ComparisonSection"));

// Loading placeholder component
const SectionLoader = () => (
  <div className="h-48 flex items-center justify-center">
    <div className="animate-pulse flex space-x-4">
      <div className="rounded-full bg-gray-200 h-12 w-12"></div>
      <div className="flex-1 space-y-4 py-1">
        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
        <div className="space-y-2">
          <div className="h-4 bg-gray-200 rounded"></div>
          <div className="h-4 bg-gray-200 rounded w-5/6"></div>
        </div>
      </div>
    </div>
  </div>
);

const LandingPage = () => {
  const [isModalVisible, setModalVisible] = useState(false);

  const handleOpenModal = () => setModalVisible(true);
  const handleCloseModal = () => setModalVisible(false);

  return (
    <div
      className="flex flex-col min-h-screen bg-white"
      // className="flex flex-col min-h-screen bg-gray-100"
    >
      <Header />
      <main className="flex-grow">
        <div className="container mx-auto w-full max-w-[1440px] px-4 sm:px-6 lg:px-8">
          {/* Hero Section */}
          <div className="mb-16 sm:mb-24">
            <HeroSection
              isVisible={isModalVisible}
              onClose={handleCloseModal}
              onOpen={handleOpenModal}
            />
          </div>

          <Suspense fallback={<SectionLoader />}>
            {/* Statistics Section - New */}
            <div className="mb-16 sm:mb-24">
              <StatisticsSection />
            </div>

            {/* Video Section */}
            {/* <div className="mb-16 sm:mb-24">
              <VideoSection />
            </div> */}

            <div className="mb-16 sm:mb-24">
              <SchedulingSection />
            </div>

            {/* Features Section */}
            {/* <div className="mb-16 sm:mb-24">
              <FeaturesSection />
            </div> */}

            {/* Comparison Section - New */}
            <div className="mb-16 sm:mb-24">
              <ComparisonSection />
            </div>

            {/* Upcoming Section */}
            <div className="mb-16 sm:mb-24">
              <UpcomingSection />
            </div>

            {/* Integration Section - New */}
            <div className="mb-16 sm:mb-24">
              <IntegrationSection />
            </div>

            {/* Shift Management Section */}
            <div className="mb-16 sm:mb-24">
              <ShiftManagementSection />
            </div>

            {/* Testimonials Section */}
            <div className="mb-16 sm:mb-24">
              <TestimonialsSection />
            </div>

            {/* Get Section */}
            <div className="mb-16 sm:mb-24">
              <GetSection />
            </div>

            {/* FAQ Section */}
            <div className="mb-16">
              <FAQSection />
            </div>
          </Suspense>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default LandingPage;
