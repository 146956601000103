import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const BookDemoPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
    console.log("Form submitted", formData);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">Book a Demo</h1>
        <p className="text-gray-600 mb-8 text-lg">
          Experience how our platform can transform your shift scheduling and
          workforce management. Fill out the form below, and our team will reach
          out to schedule a personalized demo.
        </p>

        <div className="bg-white p-8 rounded-lg shadow-md max-w-2xl mx-auto">
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label
                htmlFor="name"
                className="block text-gray-700 font-semibold mb-2"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full border rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
                placeholder="Enter your full name"
                required
              />
            </div>

            <div className="mb-6">
              <label
                htmlFor="email"
                className="block text-gray-700 font-semibold mb-2"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full border rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
                placeholder="Enter your email address"
                required
              />
            </div>

            <div className="mb-6">
              <label
                htmlFor="company"
                className="block text-gray-700 font-semibold mb-2"
              >
                Company Name
              </label>
              <input
                type="text"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleChange}
                className="w-full border rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
                placeholder="Enter your company name"
                required
              />
            </div>

            <div className="mb-6">
              <label
                htmlFor="message"
                className="block text-gray-700 font-semibold mb-2"
              >
                Message (Optional)
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full border rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
                placeholder="Let us know if you have any specific questions or requirements"
                rows="4"
              ></textarea>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="bg-primary text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-700 transition focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
              >
                Submit Request
              </button>
            </div>
          </form>
        </div>

        <div className="mt-12 text-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Why Book a Demo?
          </h2>
          <p className="text-gray-600 mb-4">Discover how our platform can:</p>
          <ul className="list-disc list-inside text-gray-600 mx-auto max-w-lg">
            <li>Streamline shift scheduling for your organization</li>
            <li>Improve workforce communication and engagement</li>
            <li>Optimize labor costs and efficiency</li>
            <li>Provide actionable insights with analytics and reporting</li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BookDemoPage;
