import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const ResourcesPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">Resources</h1>

        {/* Articles & Guides */}
        <div className="mb-12">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">
            Articles & Guides
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                Best Practices for Employee Scheduling
              </h3>
              <p className="text-gray-600">
                Learn how to streamline scheduling and optimize your workforce
                effectively.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                Minimizing Overtime Costs
              </h3>
              <p className="text-gray-600">
                Explore strategies to control labor costs while maintaining
                productivity.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                Building an Engaged Shift Workforce
              </h3>
              <p className="text-gray-600">
                Tips and tricks for improving engagement and morale among shift
                workers.
              </p>
            </div>
          </div>
        </div>

        {/* Webinars or Videos */}
        <div className="mb-12">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">
            Webinars & Videos
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                Product Walkthrough
              </h3>
              <p className="text-gray-600">
                Watch a detailed walkthrough of our platform and its features.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                Expert Interviews
              </h3>
              <p className="text-gray-600">
                Insights and tips from industry leaders on workforce management.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                Tutorials
              </h3>
              <p className="text-gray-600">
                Step-by-step tutorials to help you get started and maximize
                productivity.
              </p>
            </div>
          </div>
        </div>

        {/* Case Studies & Success Stories */}
        <div className="mb-12">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">
            Case Studies & Success Stories
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                Healthcare Facility Success
              </h3>
              <p className="text-gray-600">
                Discover how a hospital improved efficiency and reduced labor
                costs with our platform.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                Retail Operations Streamlined
              </h3>
              <p className="text-gray-600">
                See how a retail chain managed peak periods and improved
                scheduling accuracy.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                Hospitality Workforce Optimization
              </h3>
              <p className="text-gray-600">
                Learn how a hotel reduced scheduling conflicts and improved
                employee satisfaction.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResourcesPage;
