import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PricingSection from "../components/PricingSection";

const PricingPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="container mx-auto px-4 py-12">
        <PricingSection />
      </div>
      <Footer />
    </div>
  );
};

export default PricingPage;
