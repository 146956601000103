import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const ProductsPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">
          Detailed Feature Overview
        </h1>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-4">
              Dynamic Scheduling & Shift Creation
            </h2>
            <p className="text-gray-600 mb-4">
              Managers can set up shifts, locations, and required roles with
              ease, ensuring seamless workforce planning.
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-4">
              Real-Time Updates & Notifications
            </h2>
            <p className="text-gray-600 mb-4">
              Staff receive immediate alerts for changes or open shifts, keeping
              everyone informed and proactive.
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-4">
              Shift Swapping & Employee Requests
            </h2>
            <p className="text-gray-600 mb-4">
              Employees can easily request shift trades, days off, or mention
              unavailability, improving flexibility and satisfaction.
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-4">Time & Attendance</h2>
            <p className="text-gray-600 mb-4">
              Built-in clock-in/out and timesheet features, with third-party
              integration options for added convenience.
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-4">
              Analytics & Reporting
            </h2>
            <p className="text-gray-600 mb-4">
              Gain insights into labor costs, track overtime, and optimize
              workforce utilization with detailed analytics.
            </p>
          </div>
        </div>

        <div className="mt-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Benefits for Managers & Employees
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                For Managers:
              </h3>
              <ul className="list-disc list-inside text-gray-600">
                <li>Save time and reduce scheduling errors.</li>
                <li>
                  Streamline workforce planning and improve communication.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                For Employees:
              </h3>
              <ul className="list-disc list-inside text-gray-600">
                <li>Quick access to schedules and shift details.</li>
                <li>Easy shift requests and enhanced communication.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mt-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Industry-Agnostic Examples
          </h2>
          <p className="text-gray-600 mb-4">
            Our solution works seamlessly across various industries:
          </p>
          <ul className="list-disc list-inside text-gray-600">
            <li>Retail: Manage store clerk scheduling efficiently.</li>
            <li>Restaurants: Ensure coverage for busy shifts with ease.</li>
            <li>
              Hotels: Streamline housekeeping and concierge team planning.
            </li>
            <li>Healthcare: Simplify medical facility shift coordination.</li>
          </ul>
        </div>

        <div className="mt-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Visual Demo / Screenshots
          </h2>
          <p className="text-gray-600 mb-4">
            Explore our interface with annotated screenshots or watch a guided
            carousel demo to see the features in action.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductsPage;
