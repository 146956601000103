import React, { useState } from "react";
import themeStyles from "../styles/theme.styles";
import Modal from "./SignUpForDemoModal";

const HeroSection = ({ isVisible, onClose, onOpen }) => {
  return (
    <section className="container mx-auto flex flex-col items-center justify-center px-4 sm:px-8 lg:px-16 py-20 lg:py-40 bg-white text-center">
      <div className="w-full lg:w-2/3">
        <h1 className="text-[37] sm:text-3xl lg:text-6xl font-bold text-gray-800 leading-snug">
          Streamline Your Shift Scheduling for Any Industry
        </h1>
        <p className="text-base sm:text-lg text-gray-600 mt-4 leading-relaxed">
          Our all-in-one platform optimizes staffing, increases workforce
          engagement, and simplifies shift management—whether you run a
          hospital, restaurant, retail store, or any shift-based organization.
        </p>
        <div className="flex flex-col sm:flex-row gap-4 mt-6 justify-center">
          <button
            className="bg-primary text-white px-6 py-3 rounded border-2 border-primary font-semibold hover:bg-blue-700 transition inline-flex items-center"
            style={{ backgroundColor: themeStyles?.PRIMARY_COLOR }}
            onClick={onOpen}
          >
            Sign up for an invite
          </button>
          <button
            className="bg-white text-primary px-6 py-3 rounded border-2 border-primary font-semibold hover:bg-gray-200 transition inline-flex items-center"
            style={{
              color: themeStyles?.PRIMARY_COLOR,
              borderColor: themeStyles?.PRIMARY_COLOR,
            }}
          >
            Book A Free Demo
          </button>
        </div>
      </div>

      {/** Modal for Sign up */}
      <Modal isVisible={isVisible} onClose={onClose}>
        <h2 className="text-lg font-bold mb-4">Sign Up for an Invite</h2>
        <form>
          <div className="mb-4">
            <label htmlFor="name" className="block text-gray-700 font-semibold">
              Name
            </label>
            <input
              type="text"
              id="name"
              className="w-full border rounded px-3 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
              placeholder="Enter your name"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 font-semibold"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="w-full border rounded px-3 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
              placeholder="Enter your email"
            />
          </div>
          <button
            type="submit"
            className="bg-primary text-white px-6 py-3 rounded font-semibold hover:bg-blue-700 transition focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          >
            Submit
          </button>
        </form>
      </Modal>
    </section>
  );
};

export default HeroSection;
