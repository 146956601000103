import React from "react";

const SchedulingSection = () => {
  return (
    <section className="py-16">
      <div className="container mx-auto px-4 flex flex-col lg:flex-row items-center">
        {/* Left Text Section */}
        <div className="w-full lg:w-1/2 text-center lg:text-left mb-12 lg:mb-0">
          <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
            Full control, from scheduling to payroll
          </h2>
          <p className="text-lg text-gray-600 mb-6">
            Easily create and send schedules, accurately track work hours to
            digital timesheets, and ensure timely and accurate payroll
            processing.
          </p>
          <div className="grid grid-cols-2 gap-4 text-left">
            <div className="flex items-center">
              <span className="text-primary font-bold text-lg mr-2">✓</span>
              <span className="text-gray-700">Team Scheduling</span>
            </div>
            <div className="flex items-center">
              <span className="text-primary font-bold text-lg mr-2">✓</span>
              <span className="text-gray-700">Time Clock</span>
            </div>
            <div className="flex items-center">
              <span className="text-primary font-bold text-lg mr-2">✓</span>
              <span className="text-gray-700">Geofence</span>
            </div>
            <div className="flex items-center">
              <span className="text-primary font-bold text-lg mr-2">✓</span>
              <span className="text-gray-700">One-click Payroll</span>
            </div>
          </div>
        </div>

        {/* Right Image Section */}
        <div className="w-full lg:w-1/2 flex justify-center items-center">
          <div className="relative w-full max-w-md">
            <img
              src="/assets/scheduling_feature.png"
              alt="Work Schedule"
              className="rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SchedulingSection;
